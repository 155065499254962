<template>
    <BaseModal ref="base_modal" @close="close">
    <label class="modal-heading" v-html="resource[lang].heading" />

    <form @submit.prevent="add">
        <InputTextArea v-model="html" :label="resource[lang].insert_html" required />

        <div class="actions flex">
            <Button color="accent" :label="resource[lang].add_html" submit />
            <Button color="white" :label="resource[lang].cancel" @click="close" />
        </div>
    </form>
    
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/html-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import InputTextArea from '@/components/basic/InputTextArea'

export default {
    components: {
        BaseModal,
        Button,
        InputTextArea
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            html: '',

            resource
        } 
    },
    methods: {
        add() {
            this.$emit('add', this.html);
            this.$refs.base_modal.closeModal();
            this.html = '';
        },
        close() {
            this.html = '';
            this.$refs.base_modal.closeModal();
            this.$emit('close');
        },
    },
    emits: ['add', 'cancel']
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 1rem;
    justify-content: space-between;
}

</style>