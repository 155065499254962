<template>
    <BaseModal ref="base_modal" @close="close">
        <label class="modal-heading" v-html="resource[lang].heading" />

        <div>
            <q-radio 
                color="accent"
                v-for="(option, index) in $languageOptions" 
                :key="index" 
                v-model="input_lang" 
                :val="option" 
                :label="option" 
            />
        </div>

        <form @submit.prevent="updateModule">
            <InputText :label="resource[lang].name" v-model="edit_learning_module.name[input_lang]" required />
            <InputTextArea :label="resource[lang].description" v-model="edit_learning_module.description[input_lang]" />

            <q-toggle
                v-model="edit_learning_module.published"
                color="primary"
                keep-color
                :label="resource[lang].published"
            />

            <div class="actions flex">
                <Button color="accent" :label="resource[lang].update" submit />
                <Button color="white" :label="resource[lang].discard" @click="close" />
            </div>
        </form>

    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/learning-module-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'
import InputTextArea from '@/components/basic/InputTextArea'

import { promisePutRequest } from '@/assets/js/axios-utilities'

export default {
    inject: ['$languageOptions'],
    props: {
        learning_module: {
            type: Object,
            required: true,
        }
    },
    components: {
        BaseModal,
        Button,
        InputText,
        InputTextArea,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
            input_lang: lang,

            edit_learning_module: null,

            resource
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.edit_learning_module = this.learning_module;

            if (!this.edit_learning_module.description || this.edit_learning_module.description == '') {
                this.edit_learning_module.description = {de: '', en: ''};
            }
        },

        // Requests
        updateLearningModuleRequest() {
            return promisePutRequest(`learning-module/${this.edit_learning_module.id}`, this.edit_learning_module);
        },

        // Actions
        async updateModule() {
            this.$store.dispatch('loading', true);
            const response = await this.updateLearningModuleRequest();
            this.$store.dispatch('loading', false);

            this.$emit('update', response.data.learning_module);
            this.edit_learning_module = null;
            this.$refs.base_modal.closeModal();
        },
        close() {
            this.edit_learning_module = null;
            this.$refs.base_modal.closeModal();
            this.$emit('close');
        },
    },
    emits: ['update', 'close']
}
</script>

<style lang="scss" scoped>

form > * + * {
    margin-block-start: 1rem;
}

.actions {
    margin-block-start: 2rem;

    justify-content: space-between;
}

</style>

