const resource = {
    en: {
        heading: 'Add File',
        upload_file: 'Upload File',
        load_more: 'load more',
        caption: 'Caption',
        insert: 'insert',
        discard: 'discard',
        upload: 'upload',
        name: 'Name',
        alt_text: 'Alternative Text',
        description: 'Description',
        choose_language: 'Choose Language',
        file_properties: 'File Properties',
        file: 'file',
        click_to_select: 'Click to Select',
        enter_search_term: 'Enter Search Term',
        search: 'Search',
    },
    de: {
        heading: 'Datei hinzufügen',
        upload_file: 'Datei Hochladen',
        load_more: 'mehr laden',
        caption: 'Elementunterschrift',
        insert: 'einfügen',
        discard: 'verwerfen',
        upload: 'hochladen',
        name: 'Name',
        alt_text: 'Alternativ-Text',
        description: 'Beschreibung',
        choose_language: 'Sprache auswählen',
        file_properties: 'Dateieigenschaften',
        file: 'Datei',
        click_to_select: 'Klicken um Datei auszuwählen',
        enter_search_term: 'Suchbegriff eingeben',
        search: 'Suchen',
    }
}

export default resource;