<template>
    <div class="editor">
        <q-field
            :modelValue="modelValue"
            :readonly="!editable"
            :rules="required ? [ val => !!val || '* Required'] : ''"
        >
            <q-editor square flat color="primary" max-height="80vh"
                ref="editor_ref"
                content-class="editor-content"
                :modelValue="modelValue"
                :readonly="!editable"
                :placeholder="placeholder"
                @paste.stop.prevent="paste_capture"
                @update:modelValue="update"
                :definitions="{
                    insertFile: {
                        tip: 'Select or Upload a file',
                        icon: 'fas fa-file',
                        tip: 'File',
                        handler: showFileModal,
                    },
                    insertHtml: {
                        tip: 'Insert HTML Code',
                        icon: 'fas fa-code',
                        tip: 'HTML',
                        handler: showHtmlModal,
                    }
                }"
                :toolbar="[
                    [
                        {
                            label: '',
                            icon: $q.iconSet.editor.align,
                            fixedLabel: true,
                            list: 'only-icons',
                            options: ['left', 'center', 'right', 'justify']
                        },
                    ],
                    [
                        {
                            icon: $q.iconSet.editor.bold,
                            options: ['bold', 'italic', 'underline'],
                        }
                    ],
                    ['hr', 'link'],
                    [
                        {
                            label: '',
                            icon: $q.iconSet.editor.formatting,
                            list: 'no-icons',
                            options: [
                            'p',
                            'h2',
                            'h3',
                            'h4',
                            'h5',
                            ]
                        }
                    ],
                    ['quote', 'unordered', 'ordered', 'indent', 'outdent'],
                    ['undo', 'redo'],
                    [
                        {
                            label: 'Insert',
                            icon: 'far fa-plus-square',
                            options: ['insertFile', 'insertHtml']
                        }
                    ]
                ]"
            />
        </q-field>
    </div>

    <FileModal 
        :return_lang="return_lang"
        v-if="showFileModalFlag"
        @add="handleInsert"
        @cancel="cancelFileModal"
    />

    <HtmlModal
        v-if="showHtmlModalFlag"
        @add="handleInsert"
        @cancel="cancelHtmlModal"
    />

</template>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import FileModal from '@/components/modals/FileModal'
import HtmlModal from '@/components/modals/HtmlModal'

import { ref } from 'vue';



export default {
    props: {
        modelValue: String,
        required: Boolean,
        editable: Boolean,
        placeholder: String,
        return_lang: {
            type: String,
            required: true,
        }
    },
    components: {
        FileModal,
        HtmlModal
    },
    data() {
        return {
            showFileModalFlag: false,
            showHtmlModalFlag: false,

            axios
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    setup() {
        const editor_ref = ref(null);

        /**
        * Capture the <CTL-V> paste event, only allow plain-text, no images.
        *
        * see: https://stackoverflow.com/a/28213320
        *
        * @param {object} evt - array of files
        * @author Daniel Thompson-Yvetot
        * @license MIT
        */
        function paste_capture (evt) {
            // Let inputs do their thing, so we don't break pasting of links.
            if (evt.target.nodeName === 'INPUT') return
            let text, onPasteStripFormattingIEPaste
            evt.preventDefault()
            if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
                text = evt.originalEvent.clipboardData.getData('text/plain')
                editor_ref.value.runCmd('insertText', text)
            }
            else if (evt.clipboardData && evt.clipboardData.getData) {
                text = evt.clipboardData.getData('text/plain')
                editor_ref.value.runCmd('insertText', text)
            }
            else if (window.clipboardData && window.clipboardData.getData) {
                if (!onPasteStripFormattingIEPaste) {
                    onPasteStripFormattingIEPaste = true
                    editor_ref.value.runCmd('ms-pasteTextOnly', text)
                }
                onPasteStripFormattingIEPaste = false
            }
        }

        return {
            editor_ref,
            paste_capture
        }
    },
    created() {
        if (this.modelValue === '') {
            this.$emit('update:modelValue', '<div></div>');
        }
    },
    methods: {
        // v-model
        update(event) {
            this.$emit('update:modelValue', event);
        },

        // modals
        showFileModal() {
            this.showFileModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelFileModal() {
            this.showFileModalFlag = false;
        },
        showHtmlModal() {
            this.showHtmlModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelHtmlModal() {
            this.showHtmlModalFlag = false;
        },


        // insert image function for editor
        handleInsert(html) {
            this.editor_ref.runCmd('insertHTML', html);
            this.showFileModalFlag = false;
        },
    }
}
</script>

<style lang="scss">

.q-editor {
    width: 100% !important;
    min-height: 50vh;
}

.q-menu {
    div {
        font-size: 1.2rem;
        line-height: 1.37;
    }

    h2 {
        font-family: var(--standard-font);
        font-size: 3rem;
        font-weight: 400;

        line-height: 1.1;
    }

    h3 {
        font-family: var(--standard-font);
        font-size: 2.2rem;
        font-weight: 400;

        line-height: 1.2;
    }

    h4 {
        font-family: var(--standard-font);
        font-size: 1.5rem;
        font-weight: 500;

        line-height: 1.2;
    }

    h5 {
        font-family: var(--standard-font);
        font-size: 1.25rem;
        font-weight: 600;

        line-height: 1.4;
    }
}

</style>