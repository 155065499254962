const resource = {
    en: {
        heading: 'Add HTML',
        insert_html: 'Insert HTML',
        add_html: 'Add HTML',
        cancel: 'Cancel',
    },
    de: {
        heading: 'HTML hinzufügen',
        insert_html: 'HTML einfügen',
        add_html: 'HTML hinzufügen',
        cancel: 'Abbrechen',
    }
}

export default resource;