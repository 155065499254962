const resource = {
    en: {
        heading: 'Learning Modules',
        overview: 'Overview',
        add_page: 'Add Learning Module Page',
        add_page_content: 'Add Page Content...',
        page_name: 'Page Name',
        save: 'Save',
        discard: 'Discard Changes',
        published: 'Show to Public',
        delete: 'delete',
        edit_area: 'Edit Learning Module Page',
        notifications: {
            saved: 'The page has been saved',
            discard: 'The changes were discarded',
            deleted: 'The page has been deleted',
        },
        tooltips: {
            move_up: 'Move Up',
            move_down: 'Move Down',
            edit_learning_module: 'Edit Learning Module',
        }
    },
    de: {
        heading: 'Lernmodule',
        overview: 'Übersicht',
        add_page: 'Seite hinzufügen',
        add_page_content: 'Inhalt der Seite hier einfügen...',
        page_name: 'Name der Seite',
        save: 'Speichern',
        discard: 'Änderungen verwerfen',
        published: 'öffentlich zeigen',
        delete: 'löschen',
        edit_area: 'Learnmodulseite bearbeiten',
        notifications: {
            saved: 'Die Seite wurde gespeichert',
            discard: 'Die Änderungen wurden verworfen',
            deleted: 'Die Seite wurde gelöscht',
        },
        tooltips: {
            move_up: 'Nach oben verschieben',
            move_down: 'Nach unten verschieben',
            edit_learning_module: 'Lernmodul bearbeiten',
        }
    }
}

export default resource;