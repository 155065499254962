<template>
    <BaseModal wide ref="base_modal" @close="closeModal">
        <label class="modal-heading" v-html="resource[lang].heading" />
        <div v-if="area == 'initial'" class="initial">
            <Button 
                class="upload-button" 
                color="accent" 
                :label="resource[lang].upload_file" 
                @click="showUploadArea" 
            />

            <div class="search grid">
                <InputText :label="resource[lang].enter_search_term" v-model="search_term" />
                <Button color="primary" :label="resource[lang].search" @click="search" />
            </div>

            <File 
                for_modal
                v-for="file in files" 
                :key="file.id" 
                :file="file" 
                @select="selectFile"
            />
            <Button 
                v-if="!all_files"
                class="load-more" 
                color="white" 
                :label="resource[lang].load_more" 
                @click="loadFiles" 
            />
        </div>

        <div v-if="area == 'selected'" class="selected">
            <div class="insert-object-container">
                <div class="insert-object" v-html="html" />
            </div>
            
            <InputTextArea :label="resource[lang].caption" v-model="caption" />

            <div class="actions flex">
                <Button color="accent" :label="resource[lang].insert" @click="insertFile" />
                <Button color="white" :label="resource[lang].discard" @click="closeModal" />
            </div>
            
        </div>

        <div v-if="area == 'upload'" class="upload">
            <form @submit.prevent="uploadFile">
                <q-file clearable square
                    color="white" 
                    standout="bg-accent text-white"
                    v-model="file" 
                    :label="resource[lang].file"
                    :rules="[val => val || resource[lang].choose_file]"
                >
                    <template v-slot:prepend>
                        <q-icon name="fas fa-paperclip" />
                    </template>

                    <template v-slot:hint>
                        {{ resource[lang].click_to_select }}
                    </template>
                </q-file>

                <div v-if="file" class="file-properties">
                    <label class="modal-heading-2" v-html="resource[lang].file_properties" />
                    <div class="top-bar flex">
                        <p class="modal-text" v-html="resource[lang].choose_language" />
                        <div>
                            <q-radio 
                                color="accent"
                                v-for="(option, index) in $languageOptions" 
                                :key="index" 
                                v-model="input_lang" 
                                :val="option" 
                                :label="option" 
                            />
                        </div>
                    </div>
                    
                    <InputText :label="resource[lang].name" v-model="name" />
                    <InputTextArea :label="resource[lang].alt_text" v-model="alt_text[input_lang]" />
                    <InputTextArea :label="resource[lang].description" v-model="description[input_lang]" />
                </div>


                <div class="actions flex">
                    <Button color="accent" :label="resource[lang].upload" submit />
                    <Button color="white" :label="resource[lang].discard" @click="closeModal" />
                </div>

            </form>

        </div>
        
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/file-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import File from '@/components/File'
import InputText from '@/components/basic/InputText'
import InputTextArea from '@/components/basic/InputTextArea'

import { promiseGetRequest, promisePostRequest } from '@/assets/js/axios-utilities'

export default {
    props: {
        return_lang: {
            type: String,
            required: true,
        }
    },
    inject: ['$languageOptions'],
    components: {
        BaseModal,
        Button,
        File,
        InputText,
        InputTextArea,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
            input_lang: lang,

            area: 'initial',

            files: '',
            file_page: 1,
            all_files: true,

            // selected
            selected_file: null,
            html: null,
            caption: '', 

            search_term: '',
            applied_search_term: '',

            // upload variables
            file: null,
            name: '',
            alt_text: {de:'', en:''},
            description: {de:'', en:''},
            
            resource
        }

    },
    created() {
        this.loadFiles();
    },
    methods: {
        // Requests
        getFilesRequest() {
            let route = `get-files?page=${this.file_page}`;

            if (this.applied_search_term) {
                route = `${route}&search=${this.applied_search_term}`;
            }
            return promiseGetRequest(route);
        },
        uploadFileRequest() {
            const form_data = new FormData();
            form_data.append('file', this.file);
            form_data.append('name', this.name);
            form_data.append('alt_text', this.alt_text);
            form_data.append('description', this.description);
            return promisePostRequest('file', form_data);
        },

        // Actions
        closeModal() {
            this.$emit('cancel');
            this.$refs.base_modal.closeModal();
        },
        showUploadArea() {
            this.area = 'upload';
        },
        selectFile(id) {
            this.selected_file = this.files.find(file => file.id == id);
            this.area = 'selected';
            this.html = this.getHtmlForFile(this.selected_file);
            this.caption = this.selected_file.description ? this.selected_file.description[this.lang] : '';
        },
        async uploadFile() {
            this.$store.dispatch('loading', true);
            const response = await this.uploadFileRequest();
            this.$store.dispatch('loading', false);

            this.selected_file = response.data.file;
            this.area = 'selected';
            this.html = this.getHtmlForFile(this.selected_file);
            this.caption = this.selected_file.description ? this.selected_file.description[this.lang] : '';
        },
        search() {
            this.applied_search_term = this.search_term;

            this.files = [];
            this.file_page = 1;

            this.loadFiles();
        },

        // Functions
        async loadFiles() {
            this.$store.dispatch('loading', true);
            const response = await this.getFilesRequest();
            this.$store.dispatch('loading', false);

            this.files = [...this.files, ...response.data];

            this.file_page += 1;

            if (response.data.length == 10) {
                this.all_files = false;
            } else {
                this.all_files = true;
            }
        },
        insertFile() {
            let final_html = '';
            if (this.caption) {
                final_html = `<div>${this.html}<p>${this.caption}</p></div>`;
            } else {
                final_html = `<div>${this.html}</div>`;
            }
            
            this.$emit('add', final_html);
            this.$refs.base_modal.closeModal();
        },
        getHtmlForFile(file) {
            if (file.mime_type.includes('image')) {
                return `<img src="${file.public_route}" alt="${file.alt_text[this.return_lang]}">`;
            } else if (file.mime_type.includes('audio')) {
                return `<audio controls src="${file.public_route}">Your browser does not support the <code>audio</code> element.</audio>`;
            } else if (file.mime_type.includes('video')) {
                return `<video controls src="${file.public_route}">Your browser does not support the <code>video</code> element.</video>`;
            } else {
                return `<a href="${file.public_route}" target"_blank">${file.name}</a>`;
            }
        }
    },
    watch: {
        file: function(val) {
            this.name = val.name ? val.name : '';
        },
    },
    emits: ['add', 'cancel']
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 2rem;

    justify-content: space-between;
}

.initial {
    & > * + * {
        margin-block-start: 1rem;
    }
}

.selected .insert-object {
    position: relative;

    margin-inline-start: auto;
    margin-inline-end: auto;

    max-width: 14rem;

    margin-block-end: 2rem;
}

.upload form {
    .file-properties > * + * {
        margin-block-start: 1rem;
    }

    .top-bar {
        & > * {
            margin: 0 !important;
        }

        justify-content: space-between;
    }
}

</style>

<style lang="scss">

img {
    max-width: 100%;
}

</style>