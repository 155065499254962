const resource = {
    en: {
        heading: 'Edit Learning Module',
        name: 'Name',
        description: 'Description',
        update: 'Update',
        discard: 'Discard',
        published: 'Show to Public',
    },
    de: {
        heading: 'Lernmodul bearbeiten',
        name: 'Name',
        description: 'Beschreibung',
        update: 'Aktualisieren',
        discard: 'Änderungen verwerfen',
        published: 'öffentlich zeigen',
    }
}

export default resource;