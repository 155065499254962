<template>
    <div>

        <div class="dashboard-main">
            <h1 class="dashboard-main-heading" v-html="resource[lang].heading" />

            <div class="overview">
                <h2 class="basic-form-heading-2" v-html="resource[lang].overview" />
                <ol class="list-level-1">
                    <li 
                        v-for="module in learning_modules" 
                        :key="module.id"
                        :class="active_learning_module && active_learning_module.id == module.id && 'active'"
                        @click="active_learning_module = module"
                    > 
                        <span v-html="module.name[lang]" />
                        <q-icon 
                            name="fas fa-pen" 
                            size="1rem" 
                            color="dark_grey"
                            class="edit-button"
                            tabindex="0"
                            @click="showLearningModuleModal(module)"
                        >
                            <q-tooltip v-html="resource[lang].tooltips.edit_learning_module" />
                        </q-icon>
                        <ol v-if="module.pages" class="list-level-2">
                            <li 
                                v-for="(page, index) in module.pages" 
                                :key="page.id" 
                                :class="active_learning_module_page && active_learning_module_page.id == page.id && 'active'"
                            >
                                <span 
                                    v-html="page.name[lang]" 
                                    @click="active_learning_module_page = JSON.parse(JSON.stringify(page))" 
                                />
                                <q-icon 
                                    v-if="index != module.pages.length - 1"
                                    name="fas fa-arrow-down" 
                                    size="1rem" 
                                    color="dark_grey"
                                    class="move-button move-down-button"
                                    tabindex="0"
                                    @click="movePageDown(index)"
                                >
                                    <q-tooltip v-html="resource[lang].tooltips.move_down" />
                                </q-icon>
                                <q-icon 
                                    v-if="index != 0"
                                    name="fas fa-arrow-up" 
                                    size="1rem" 
                                    color="dark_grey"
                                    class="move-button move-up-button"
                                    tabindex="0"
                                    @click="movePageUp(index)"
                                >
                                    <q-tooltip v-html="resource[lang].tooltips.move_up" />
                                </q-icon>
                            </li>
                            <li class="add-button" v-html="resource[lang].add_page" @click="addPage" />
                        </ol>
                    </li>
                </ol>
            </div>

            <div class="edit-area" v-if="active_learning_module_page != null">
                <div class="top-bar flex">
                    <h2 class="basic-form-heading-2" v-html="resource[lang].edit_area" />
                    <div>
                        <q-radio 
                            color="accent"
                            v-for="(option, index) in $languageOptions" 
                            :key="index" 
                            v-model="input_lang" 
                            :val="option" 
                            :label="option" 
                        />
                    </div>
                </div>
                
                <InputText required
                    v-model="active_learning_module_page.name[input_lang]" 
                    :label="resource[lang].page_name"  
                />
                
                <Editor required editable
                    :return_lang="input_lang"
                    v-model="active_learning_module_page.content[input_lang]" 
                    :placeholder="resource[lang].add_page_content"  
                />

                <q-toggle
                    v-model="active_learning_module_page.published"
                    color="primary"
                    keep-color
                    :label="resource[lang].published"
                />

            </div>

        </div>

        <div v-if="active_learning_module_page != null" class="actions flex">
            <div>
                <Button color="accent" :label="resource[lang].save" @click="savePage" />
            </div>
            <div>
                <Button color="secondary" :label="resource[lang].delete" @click="showDeleteModal" />
                <Button color="primary" :label="resource[lang].discard" @click="discardPage" />
            </div>
        </div>

        <DeleteModal
            v-if="showDeleteModalFlag"
            @confirm="deletePage"
            @cancel="cancelDeleteModal"
        />

        <LearningModuleModal
            v-if="showLearningModuleModalFlag"
            :learning_module="edit_learning_module"
            @update="updateLearningModule"
            @close="cancelLearningModuleModal"
        />
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex';
import { useQuasar } from 'quasar';

import Button from '@/components/basic/Button'
import DeleteModal from '@/components/modals/DeleteModal'
import Editor from '@/components/basic/Editor'
import InputText from '@/components/basic/InputText'
import LearningModuleModal from '@/components/modals/LearningModuleModal'

import { promiseDeleteRequest, promiseGetRequest, promisePostRequest, promisePutRequest } from '@/assets/js/axios-utilities'

import resource from '@/assets/resources/project-learning-modules-resource'

export default {
    inject: ['$languageOptions'],
    components: {
        Button,
        DeleteModal,
        Editor,
        InputText,
        LearningModuleModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
            input_lang: lang,

            learning_modules: null,

            active_learning_module: null,
            active_learning_module_page: null,

            showLearningModuleModalFlag: false,
            edit_learning_module: null,

            showDeleteModalFlag: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    async created() {
        this.$store.dispatch('loading', true);
        const response = await this.getLearningModulesRequest();
        this.learning_modules = response.data;
        this.$store.dispatch('loading', false);
    },
    methods: {
        // Requests
        getLearningModulesRequest() {
            return promiseGetRequest('full-learning-modules');
        },
        createLearningModulePageRequest(request_body) {
            return promisePostRequest('learning-module-page', request_body);
        },
        updateLearningModulePageRequest(request_body) {
            return promisePutRequest(`learning-module-page/${request_body.id}`, request_body);
        },
        deleteLearningModulePageRequest(id) {
            return promiseDeleteRequest(`learning-module-page/${id}`);
        },


        // Actions
        addPage() {
            this.active_learning_module.pages.push({
                name: {de: '', en:''}, 
                content: {de: '', en:''}, 
                order: this.active_learning_module.pages.length != 0 ? this.active_learning_module.pages[this.active_learning_module.pages.length - 1].order + 1 : 1,
                published: false,
                learning_module_id: this.active_learning_module.id,
            });
            this.active_learning_module_page = this.active_learning_module.pages[this.active_learning_module.pages.length - 1];
        },
        async savePage() {
            if ('id' in this.active_learning_module_page) {
                this.$store.dispatch('loading', true);
                await this.updateLearningModulePageRequest(this.active_learning_module_page);
                this.$store.dispatch('loading', false);

            } else {
                if (this.lang == 'en') {
                    this.active_learning_module_page.name['de'] = this.active_learning_module_page.name[this.lang];
                    this.active_learning_module_page.content['de'] = this.active_learning_module_page.content[this.lang];
                } else {
                    this.active_learning_module_page.name['en'] = this.active_learning_module_page.name[this.lang];
                    this.active_learning_module_page.content['en'] = this.active_learning_module_page.content[this.lang];
                }

                this.$store.dispatch('loading', true);
                const response = await this.createLearningModulePageRequest(this.active_learning_module_page);
                this.$store.dispatch('loading', false);
                
                this.active_learning_module_page = response.data.learning_module_page;
                const page_index = this.active_learning_module.pages.findIndex(page => !('id' in page));
                this.active_learning_module.pages[page_index] = this.active_learning_module_page;
            }

            this.quasar.notify({
                position: 'bottom-left', 
                message: this.resource[this.lang].notifications.saved,
                color: 'positive'
            });
        },
        discardPage() {
            if ('id' in this.active_learning_module_page) {
                this.active_learning_module_page = JSON.parse(JSON.stringify(this.active_learning_module.pages.find(
                    page => page.id == this.active_learning_module_page.id
                )));
            } else {
                this.active_learning_module_page.name[this.lang] = '';
                this.active_learning_module_page.content[this.lang] = '';
                this.active_learning_module_page.published = false;
            }

            this.quasar.notify({
                position: 'bottom-left', 
                message: this.resource[this.lang].notifications.discard,
                color: 'positive'
            });
        },
        async deletePage() {
            if ('id' in this.active_learning_module_page) {
                this.$store.dispatch('loading', true);
                await this.deleteLearningModulePageRequest(this.active_learning_module_page.id);
                this.$store.dispatch('loading', false);

                this.active_learning_module.pages = this.active_learning_module.pages.filter(page => page.id != this.active_learning_module_page.id);
                this.active_learning_module_page = null;
            } else {
                this.active_learning_module.pages = this.active_learning_module.pages.filter(page => 'id' in page);
                this.active_learning_module_page = null;
            }
        },
        showDeleteModal() {
            this.showDeleteModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelDeleteModal() {
            this.showDeleteModalFlag = false;
        },
        async movePageUp(index) {
            if (index > 1) {
                this.active_learning_module.pages[index].order = (this.active_learning_module.pages[index-2].order + this.active_learning_module.pages[index-1].order) / 2.0;
            } else {
                this.active_learning_module.pages[index].order = this.active_learning_module.pages[index-1].order / 2.0;
            }

            this.$store.dispatch('loading', true);
            await this.updateLearningModulePageRequest(this.active_learning_module.pages[index])
            this.$store.dispatch('loading', false);
            
            this.sortPages();
        },
        async movePageDown(index) {
            if (index < this.active_learning_module.pages.length - 2) {
                this.active_learning_module.pages[index].order = (this.active_learning_module.pages[index+2].order + this.active_learning_module.pages[index+1].order) / 2.0;
            } else {
                this.active_learning_module.pages[index].order = this.active_learning_module.pages[index+1].order + 1.0;
            }

            this.$store.dispatch('loading', true);
            await this.updateLearningModulePageRequest(this.active_learning_module.pages[index])
            this.$store.dispatch('loading', false);

            this.sortPages();
        },
        showLearningModuleModal(module) {
            this.showLearningModuleModalFlag = true;
            this.edit_learning_module = module;
            this.$store.dispatch('modal', true);
        },
        cancelLearningModuleModal() {
            this.showLearningModuleModalFlag = false;
            this.edit_learning_module = null;
        },
        updateLearningModule(learning_module) {
            const index = this.learning_modules.findIndex(module => module.id == learning_module.id);

            this.learning_modules[index] = learning_module;
            this.showLearningModuleModalFlag = false;
            this.edit_learning_module = null;
        },

        // Functions
        sortPages() {
            this.active_learning_module.pages.sort(function(a, b) {
                return a.order - b.order;
            });
        },
    },
}
</script>

<style lang="scss" scoped>

li {
    cursor: pointer;
}

ol {
    font-family: var(--standard-font);
    font-size: 0.8rem;
    font-weight: 300;

    list-style-position: inside;
}

.actions {
    margin-block-start: 1rem;
    justify-content: space-between;

    div > * + * {
        margin-inline-start: 1rem;
    }
}

.add-button {
    text-decoration: underline;
}

.edit-area {
    margin-block-start: 2rem;

    * {
        margin-block-end: 1rem;
    }
}

.edit-button {
    padding-inline-start: 1rem;

    cursor: pointer;
}

.list-level-1 > li > ol {
    display: none;
}

.list-level-1 > li.active > ol {
    display: block;
}

.list-level-2 {
    margin-left: 2rem;

    .active {
        color: var(--accent);
        font-weight: 600;
    }
}

.move-button {
    padding-inline-start: 1rem;

    cursor: pointer;
}

.move-button:hover {
    color: var(--blak) !important;
}

.top-bar {
    justify-content: space-between;

    * {
        margin: 0 !important;
    }
}

</style>