const resource = {
    en: {
        uploaded_by: 'Uploaded by',
        on: 'on',
        edit: 'edit',
        delete: 'delete',
        no_appearances: 'This file does not appear anywhere',
        select: 'select'
    },
    de: {
        uploaded_by: 'Hochgeladen von',
        on: 'am',
        edit: 'bearbeiten',
        delete: 'löschen',
        no_appearances: 'Diese Datei ist nirgends verlinkt',
        select: 'Auswählen'
    }
}

export default resource;