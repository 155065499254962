<template>
    <div class="file grid">
        <div class="icon">
            <a :href="file.public_route" target="_blank">
                <img 
                    v-if="image" 
                    :src="file.public_route" 
                    :alt="file.alt_text"
                >
                <audio controls
                    v-if="audio" 
                    :src="file.public_route" 
                >
                    Your browser does not support the <code>audio</code> element.
                </audio>
                <video controls
                    v-if="video"
                    :src="file.public_route"
                >
                    Your browser does not support the <code>video</code> element.
                </video>
                <q-icon 
                    v-if="!image && !audio && !video" 
                    class="file-icon" 
                    name="fas fa-file" 
                    color="primary" 
                    size="4rem" 
                />
            </a>
        </div>
        <div class="info">
            <p class="mime" v-html="file.mime_type" />
            <p class="name" v-html="file.name" />
            <p class="details" v-html="`${resource[lang].uploaded_by} ${file.owner.full_name} ${resource[lang].on} ${date}`" />
            <p 
                class="details no-appearances" 
                v-if="no_appearances" 
                v-html="resource[lang].no_appearances" 
            />
        </div>
        <div class="actions">
            <Button stretch
                v-if="!for_modal"
                color="primary" 
                :label="resource[lang].edit" 
                @click="$emit('edit', file.id)"  
            />
            <Button stretch
                v-if="!for_modal"
                color="secondary" 
                :label="resource[lang].delete" 
                @click="$emit('delete', file.id)"  
            />
            <Button stretch
                v-if="for_modal"
                color="primary" 
                :label="resource[lang].select" 
                @click="$emit('select', file.id)"  
            />
        </div>
    </div>
</template>

<script>
import resource from '@/assets/resources/file-resource'

import Button from '@/components/basic/Button'

export default {
    inject: ['$dateOptions'],
    props: {
        file: {
            type: Object,
            required: true,
        },
        for_modal: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    computed: {
        date() {
            return new Date(this.file.updated_at).toLocaleDateString('en-GB', this.$dateOptions);
        },
        mime_type() {
            return this.file.name;
        },
        image() {
            return this.file.mime_type.includes('image');
        },
        audio() {
            return this.file.mime_type.includes('audio');
        },
        video() {
            return this.file.mime_type.includes('video');
        },
        no_appearances() {
            if (this.file.appearances) {
                if (this.file.appearances.length > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    },
    emits: ['edit', 'delete', 'select']
}
</script>

<style lang="scss" scoped>

img {
    width: 100%;

    aspect-ratio: 1 / 1;

    object-fit: cover;
}

.actions > * + * {
    margin-block-start: 1rem;
}

.file {
    padding: 1rem;

    background-color: var(--whit);

    grid-template-columns: 1fr 3fr 1fr;
    gap: 1rem;
}

.icon {
    position: relative;

    height: 100%;

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
    }

    .file-icon {
        display: block;
        margin: auto;
    }
}

.mime {
    color: var(--primary);

    margin-block-end: 1rem;
}

.name {
    color: var(--blak);
    font-family: var(--standard-font);
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
}

.no-appearances {
    color: var(--accent);
}

</style>